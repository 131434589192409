import { blobToFile, fileToImg } from "./convert";

/**
 * @typedef {{x: number, x2: number, y: number, y2: number, width: number, height: number, imageWidth: number, imageHeight: number}} InnerRect
 * @typedef {{x: number, y: number, width: number, height: number}} Rect
 */

export function getCanvas(width, height) {
	let canvas
	// OffscreenCanvas有些问题, 后续处理
	// try {
	// 	canvas = new OffscreenCanvas(width, height)
	// } catch (error) {
		canvas = document.createElement('canvas')
		canvas.width = width
		canvas.height = height
	// }
	return canvas
}


/**
 * 创建image对象
 * @param {string} url
 * @returns {Promise<HTMLImageElement>} 
 */
export async function createImage(url) {
	const img = await new Promise((resolve, reject) => {
		const img = new Image()
		img.onload = () => {
			resolve(img)
		}
		img.onerror = (err) => {
			reject(err)
		}
		img.setAttribute('crossOrigin', 'anonymous')
		img.src = url
	})
	return img
}

/** 是否存在透明像素 */
function isExistTransparentPx(data) {
	// 第三个位置是透明度, 小于255的表示一定程度的透明, 0为完全透明
	return data < 255
}

/**
 * 给定图片是否存在透明区域
 * @param {string} url
 */
export async function isImageExistTransparentByUrl(url) {
	const img = await createImage(url)
	return isImageExistTransparent(img)
}
/**
 * 给定图片是否存在透明区域
 * @param {HTMLImageElement} img
 */
export function isImageExistTransparent(img) {
	let canvas = getCanvas(img.width, img.height)
	canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
	// 只检测1像素
	let imageData = canvas.getContext('2d').getImageData(0, 0, 1, 1);
	if (isExistTransparentPx(imageData.data[3])) {
			return true
	} else {
			return false
	}
}
/**
 * 获取透明图内最小物体的矩形, 原图片左上角为原点
 * @param {string} url
 * @param {((v: number) => number)?} convertXFunc 转换函数
 * @param {((v: number) => number)?} convertYFunc 转换函数
 * @returns {Promise<InnerRect>}
 */
export async function getTransparentImageInnerRectByUrl(url, convertXFunc, convertYFunc) {
	const img = await createImage(url)
	return getTransparentImageInnerRect(img, convertXFunc, convertYFunc)
}
/**
 * 获取透明图内最小物体的矩形, 原图片左上角为原点
 * 如果输入的不是一个透明图, 则返回原图数据
 * @param {HTMLImageElement} img
 * @param {((v: number) => number)?} convertXFunc 转换函数
 * @param {((v: number) => number)?} convertYFunc 转换函数
 * @returns {InnerRect}
 */
export function getTransparentImageInnerRect(img, convertXFunc, convertYFunc) {
	const rect = {
		x: 0,
		x2: 0,
		y: 0,
		y2: 0,
		width: 0,
		height: 0,
		imageWidth: 0,
		imageHeight: 0,
	}
	const canvas = getCanvas(img.width, img.height)
	canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
	let imageData = canvas.getContext('2d').getImageData(0, 0, img.width, img.height).data;
	if (isExistTransparentPx(imageData[3])) {
		const data = imageData
		let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;
	
		for (let y = 0; y < canvas.height; y++) {
			for (let x = 0; x < canvas.width; x++) {
				const i = (y * canvas.width + x) * 4;
				if (data[i + 3] > 0) { // 检查 alpha 值
					minX = Math.min(minX, x);
					minY = Math.min(minY, y);
					maxX = Math.max(maxX, x);
					maxY = Math.max(maxY, y);
				}
			}
		}

		rect.x = minX
		rect.x2 = maxX
		rect.y = minY
		rect.y2 = maxY
		rect.width = rect.x2 - rect.x
		rect.height = rect.y2 - rect.y
		rect.imageWidth = img.width
		rect.imageHeight = img.height
	} else {
		rect.x = 0
		rect.x2 = img.width
		rect.y = 0
		rect.y2 = img.height
		rect.width = img.width
		rect.height = img.height
		rect.imageWidth = img.width
		rect.imageHeight = img.height
	}
	if (convertXFunc && !convertYFunc) {
		convertYFunc = convertXFunc
	}
	if (convertXFunc && convertYFunc) {
		rect.x = convertXFunc(rect.x)
		rect.x2 = convertXFunc(rect.x2)
		rect.y = convertYFunc(rect.y)
		rect.y2 = convertYFunc(rect.y2)
		rect.width = convertXFunc(rect.width)
		rect.height = convertYFunc(rect.height)
		rect.imageWidth = convertXFunc(rect.imageWidth)
		rect.imageHeight = convertYFunc(rect.imageHeight)
	}

	return rect
}
/**
 * 获取透明图内部图片距周围空白大小
 * @param {{width: number, height: number}} originRect 透明图大小
 * @param {InnerRect} innerRect 
 * @returns {{left: number, right: number, top: number, bottom: number}}
 */
export function getInnerRectBlankSize(originRect, innerRect) {
	return {
    left: originRect.width * (innerRect.x / innerRect.imageWidth),
    right: originRect.width * ((innerRect.imageWidth - innerRect.x2) / innerRect.imageWidth),
    top: originRect.height * (innerRect.y / innerRect.imageHeight),
    bottom: originRect.height * ((innerRect.imageHeight - innerRect.y2) / innerRect.imageHeight),
  }
}
/**
 * 保持其宽高比地展示元素最短的边
 * @param {{width: number, height: number}} containerSize 
 * @param {{width: number, height: number}} elementSize 
 * @returns {{width: number, height: number}}
 */
export function cover(containerSize, elementSize) {
  const containerRatio = containerSize.width / containerSize.height
  const elementRatio = elementSize.width / elementSize.height

  let width, height

  if (containerRatio > elementRatio) {
    width = containerSize.width
    height = containerSize.width / elementRatio
  } else {
    width = containerSize.height * elementRatio
    height = containerSize.height
  }

  return { width, height }
}
/**
 * 保持其宽高比地完全展示元素
 * @param {{width: number, height: number}} containerSize 
 * @param {{width: number, height: number}} elementSize 
 * @returns {{width: number, height: number}}
 */
export function contain(containerSize, elementSize) {
	const containerRatio = containerSize.width / containerSize.height
  const elementRatio = elementSize.width / elementSize.height

  let width, height

  if (containerRatio < elementRatio) {
    width = containerSize.width
    height = containerSize.width / elementRatio
  } else {
    width = containerSize.height * elementRatio
    height = containerSize.height
  }
  return { width, height }
}
/**
 * 计算实际长高, 会填充空白内容
 * @param {number} originWidth 
 * @param {number} originHeight 
 * @param {number} targetWidth 
 * @param {number} targetHeight 
 * @returns {{realWidth: number, realHeight: number}}
 */
export function computedRealWidthHeight(originWidth, originHeight, targetWidth, targetHeight) {
	const {width, height} = contain({
		width: targetWidth,
		height: targetHeight,
	}, {
		width: originWidth,
		height: originHeight,
	})
	return {
		realWidth: width,
		realHeight: height,
	}
}
/**
 * 通过最大宽高等比例缩放图片像素
 * @param {File} file 
 * @param {number} maxWidth 
 * @param {number} maxHeight 
 * @returns {Promise<File>}
*/
export async function scaleImageByMaxWidthHeight(file, maxWidth, maxHeight) {
	if (!(maxWidth && maxHeight)) {
		throw new Error("最大缩放长宽无效")
	} 
	const img = await fileToImg(file)
	let cWidth
	let cHeight
	if (img.width < maxWidth && img.height < maxHeight) {
		cWidth = img.width
		cHeight = img.height
	} else {
		if (img.width < img.height) {
			cWidth = img.width / img.height * maxHeight
			cHeight = maxHeight
		} else {
			cWidth = maxWidth
			cHeight = img.height / img.width * maxWidth
		}
	}
	const canvasNode = getCanvas(cWidth, cHeight)
	const ctx = canvasNode.getContext("2d")
	ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, cWidth, cHeight)
	const blob = await new Promise((resolve) => {
		canvasNode.toBlob((blob) => resolve(blob), file.type)
	})
  const newFile = blobToFile(blob, file.name)
	return newFile
}