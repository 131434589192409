<template>
    <div class="deck-page">
        <div class="page-left">
            <div class="deck-area-box">
                <div class="deck-area-head">
                    <div class="copy-box">
                        <img src="/assets/images/deck/icon1.png" @click="$refs.imageDeck.preview()">
                        <img src="/assets/images/deck/icon2.png" @click="$refs.imageDeck.showTemplate()">
                    </div>
                    <div class="auto-btn" @click="autoScheme()">
                        <img src="/assets/images/deck/icon3.png">
                        <span>自动拼图</span>
                    </div>
                </div>
                <div class="deck-area">
                    <image-deck @deleted="deckImageDeleted" :goodsList="selectedGoods" :images="deckImages"
                        ref="imageDeck" />
                </div>
                <div class="deck-area-tool"></div>
            </div>
        </div>
        <div class="page-right">
            <div class="goods-select">
                <div class="item" @click="goodsShopActive = true">
                    <img src="/assets/images/deck/icon6.png">
                    <span>自营店铺</span>
                </div>
                <div class="item" @click="goodsStockActive = true">
                    <img src="/assets/images/deck/icon4.png">
                    <span>商品库</span>
                </div>
                <div class="item" @click="goodsCollectActive = true">
                    <img src="/assets/images/deck/icon5.png">
                    <span>收藏夹</span>
                </div>
            </div>

            <template v-if="loadingOutfit">
                <img width="48" src="/assets/images/loading.gif">
            </template>
            <template v-else-if="selectedGoods.length === 0">
                <div class="select-tips">
                    <img src="/assets/images/deck/pao-tips.png">
                    <div class="tips">选择单品进行搭配吧～</div>
                </div>
                <div class="tips-box">
                    <div class="tips-item">
                        <div class="tips-label">
                            <span>自营店铺</span>
                        </div>
                        <div class="tips">
                            <div>展示自己店铺的服饰类商品</div>
                            <div>(实物商品/其它 、虚拟商品分类将不做显示)</div>
                        </div>
                    </div>
                    <div class="tips-item">
                        <div class="tips-label">
                            <span>商品库</span>
                        </div>
                        <div class="tips">
                            <div>展示自己店铺的服饰类商品</div>
                            <div>(实物商品/其它 、虚拟商品分类将不做显示)</div>
                        </div>
                    </div>
                    <div class="tips-item">
                        <div class="tips-label">
                            <span>收藏夹</span>
                        </div>
                        <div class="tips">
                            <div>存放用户收藏的自营店铺、合作店铺服装单品</div>
                            <div>方便用户直接从收藏夹里选品进行搭配</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <perfect-scrollbar :options="{ suppressScrollY: false }" class="goods-scroll">
                    <div class="goods-list">

                        <div @click="top(goods)" class="goods-block" v-for="(goods, key) in selectedGoods"
                            :key="goods.id"
                            :class="{ 'used': usedGoods.indexOf(goods) >= 0, 'third': (key + 1) % 3 === 0 }">

                            <button @click.stop="deleteGoods(goods)" class="del-btn"><span
                                    class="iconfont pc-guanbi"></span></button>

                            <goods-item disable :goods="goods" />
                        </div>

                        <div></div>
                        <div></div>
                        <div></div>

                    </div>
                </perfect-scrollbar>

                <div class="next">
                    <button @click="next" class="btn">下一步</button>
                </div>
            </template>


        </div>

        <drawer width="720px" v-model="goodsStockActive">
            <goods-stock :selected-list="selectedGoods" type="goods" @selected="goodsSelected" />
        </drawer>

        <drawer width="720px" v-model="goodsCollectActive">
            <goods-stock :selected-list="selectedGoods" type="collect" @selected="goodsSelected" />
        </drawer>

        <drawer width="720px" v-model="goodsShopActive">
            <goods-stock :selected-list="selectedGoods" type="shop" @selected="goodsSelected" />
        </drawer>
    </div>
</template>

<script>
import api from "@/repo/api";
import auth from "@/auth";
import Drawer from "@/components/Drawer";
import GoodsItem from "@/components/GoodsItem";
// import SchemeQrcode from "@/components/SchemeQrcode";
// import DeckContent from "./Com/DeckContent.vue";
import GoodsStock from "./Com/GoodsStock.vue";
import ImageDeck from "./Com/ImageDeck.vue";

export default {
    components: { GoodsStock, Drawer, GoodsItem, ImageDeck },
    data() {
        return {
            hideMenu: true,
            showSchemeModal: true,
            selectedGoods: [],
            usedGoods: [],
            deckImages: [],
            imageDeck: null,
            drawerActive: false,
            goodsStockActive: false,
            goodsShopActive: false,
            goodsCollectActive: false,
            goodsCustomActive: false,
            outfit: null,
            goods: [],
            loadingOutfit: false,
            totalPrice: '0.00',
            totalComm: '0.00',
            appId: auth.appId(),
            detailId: 0,
            schemeType: 'bang',
            showDetailQrcode: false,
            shopGoodsTypes: [
                'wechat',
                'yc-shop',
            ]
        }
    },
    watch: {
        '$route.query'() {
            this.showDetailQrcode = false
            this.getOutfit()
        },
        usedGoods() {
            let prices = 0;
            let comm = 0

            this.usedGoods.forEach(item => {

                prices += parseFloat(item.lowest_price || (item.min_price / 100).toFixed(2))
                comm += parseFloat(item.commission || 0.00)

            })

            this.totalPrice = prices.toFixed(2)
            this.totalComm = comm.toFixed(2)
        },
    },
    mounted() {

        this.imageDeck = this.$refs['imageDeck']

        this.getOutfit()
        this.getGoods()

    },
    methods: {
        autoScheme() {

            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            let buildList = this.imageDeck.getFrames();
            let frameGoods = [];

            buildList.forEach((bitem) => {

                this.usedGoods.forEach(item => {

                    if (
                        bitem.image !== item.white_image &&
                        bitem.image !== item.thumb
                    ) return

                    let frame = {
                        w: parseInt(bitem.style.width),
                        h: parseInt(bitem.style.height),
                    }
                    if (item.yc_cate_id) {
                        frame.cate_id = item.yc_cate_id;
                    }

                    frameGoods.unshift(frame)
                })

            })

            api.post('/ast-scheme/auto-match-frame', { goods: frameGoods }, (data) => {

                this.$swal.close()

                if (data.code === 0 && data.data.length > 0) {
                    this.matchFrames(data.data[0])
                } else {
                    if (data.data.length == 0) {
                        // 没有可自动拼图的模式
                        this.$swal({
                            title: '没有可自动拼图的模式',
                            icon: 'error',
                            confirmButtonText: '好'
                        });
                    }
                }

            });

        },
        matchFrames(frameData) {

            let frame = frameData.frame.goods;

            let newFrameList = []

            this.usedGoods.forEach(item => {

                for (const frameKey in frame) {

                    let subFrame = frame[frameKey];

                    if (item.yc_cate_id != subFrame.cate_id) continue;

                    frame.splice(frameKey, 1);

                    newFrameList.push({
                        image: this.getGoodsItemImage(item),
                        type: "image",
                        active: false,
                        style: {
                            width: `${subFrame.w}px`,
                            height: `${subFrame.h}px`,
                            left: `${subFrame.x}px`,
                            top: `${subFrame.y}px`,
                            zIndex: 80 + frameKey,
                        }
                    });

                    break;
                }
            })
            this.imageDeck.setFrames(newFrameList);
        },
        showSchemeDetail(id) {

            console.log(id);

        },
        getGoods() {
            let id = this.$route.query.goods

            if (!id) return;

            api.get('/goods/ids', { ids: id }, (data) => {

                if (data.code === 0 && data.data.data.length) {
                    this.selectedGoods = data.data.data
                    this.usedGoods = data.data.data
                    this.reloadGoodsImage()
                }

            });

        },
        loadOutfit(data) {

            this.outfit = data
            this.selectedGoods = this.outfit.goods
            this.usedGoods = this.outfit.goods

            if (this.outfit.frame && this.outfit.frame.frame) {

                let list = []

                let bl = 600 / this.outfit.frame.frame.width;

                let zh = (v) => {
                    return bl * v;
                }

                this.outfit.frame.frame.goods.forEach(item => {
                    console.log('goods',item);
                    
                    let image = '';

                    this.usedGoods.forEach(goods => {
                        if (goods.id === item.id) image = goods.white_image || goods.thumb
                    })

                    if (!image) return;

                    list.unshift({
                        type: 'image',
                        image: image,
                        style: {
                            left: zh(item.x) + 'px',
                            top: zh(item.y) + 'px',
                            width: zh(item.w) + 'px',
                            height: zh(item.h) + 'px',
                            zIndex:item.z
                        },
                        active: false,
                    })

                })

                this.$refs['imageDeck'].setImageData(list)

            } else {
                this.reloadGoodsImage()
            }

        },
        getOutfit() {

            let id = this.$route.query.outfit_id

            if (!id && !this.outfit) return

            if (!id && this.outfit) {
                return location.reload()
            }
            if (id && this.outfit && this.outfit.id !== id) {
                return location.reload()
            }

            this.loadingOutfit = true

            api.get('/ast-scheme/scheme-detail', { id }, (data) => {

                this.loadingOutfit = false

                if (data.code !== 0) return

                data.data.goods.forEach(goods => {

                    if ((this.shopGoodsTypes.indexOf(goods.from) === -1) || (!goods.color)) return;

                    goods.title = goods.product_name + ' - ' + goods.color
                    goods.id = goods.id + '-' + goods.color

                })

                this.loadOutfit(data.data)
            })

        },
        getGoodsItemImage(item) {
            return item.white_image || item.thumb || item.thumb_image;
        },
        reloadGoodsImage() {

            let images = []

            this.usedGoods.forEach(item => {

                let image = this.getGoodsItemImage(item)

                if (image) images.push(image)
            })

            this.deckImages = images
        },
        goodsSelected(goods) {

            this.goodsCollectActive = false
            this.goodsStockActive = false
            this.goodsShopActive = false
            this.goodsCustomActive = false

            if (!goods || !goods.length) return

            let unused = this.cj(this.selectedGoods, this.usedGoods)

            this.usedGoods = this.cj(unused, goods)

            this.selectedGoods = goods

            this.reloadGoodsImage()
        },

        cj(old, newer) {

            let arr = []

            if (!newer.length) return [].concat(old)

            if (!old.length) return [].concat(newer)

            newer.forEach(nitem => {

                let find = false

                old.forEach(oitem => {

                    if (oitem.id === nitem.id) find = true
                })

                if (!find) arr.push(nitem)
            })

            old.forEach(nitem => {

                let find = false

                newer.forEach(oitem => {

                    if (oitem.id === nitem.id) find = true
                })

                if (!find) arr.push(nitem)
            })


            return arr
        },

        deckImageDeleted(image) {

            let idx = this.deckImages.indexOf(image)
            if (idx < 0) return

            this.deckImages.splice(idx, 1)

            idx = -1;
            this.usedGoods.forEach((item, key) => {
                if (item.white_image !== image) return
                idx = key
            })

            if (idx < 0) return
            this.usedGoods.splice(idx, 1)
        },
        deleteGoods(item) {

            let idx = this.selectedGoods.indexOf(item)

            this.selectedGoods.splice(idx, 1)

            idx = this.usedGoods.indexOf(item)
            if (idx >= 0) this.usedGoods.splice(idx, 1)

            this.reloadGoodsImage()
        },
        top(item) {

            if (this.usedGoods.indexOf(item) === -1) {

                this.usedGoods.push(item);
                this.reloadGoodsImage();

                return
            }

            this.imageDeck.active(item.white_image)
        },
        next() {

            if (!this.usedGoods.length) {

                this.$swal({
                    title: '请先完成拼图',
                    icon: 'error',
                    confirmButtonText: '好'
                });
                return
            }
            this.deckSave();
        },

        deckSave() {
            let data = {};

            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            if (this.outfit) {
                data['id'] = this.outfit.id
                data['type'] = this.outfit.type
            }

            this.imageDeck.build((img, buildList) => {

                console.log('buildList',buildList);
                
                // data['image'] = img

                localStorage.setItem('image', img);

                let goods = []
                let astGoods = []
                let frames = {
                    "width": "600",
                    "height": "600",
                }
                let frameGoods = []

                buildList.forEach((bitem) => {
                    this.usedGoods.forEach(item => {

                        if (
                            bitem.image !== item.white_image &&
                            bitem.image !== item.thumb
                        ) return

                        goods.push(item.id)

                        let type = 'bang'
                        let iid = [];


                        if (typeof item.id == 'string') {
                            iid = item.id.split('-')
                        } else {
                            iid = [item.id, '']
                        }


                        if (this.shopGoodsTypes.indexOf(item.from) >= 0) {
                            type = 'shop'
                        }

                        astGoods.push({
                            id: iid[0],
                            type,
                            color: iid[1] || '',
                            from: item.from
                        })
                        let frame = {
                            x: parseInt(bitem.style.left),
                            y: parseInt(bitem.style.top),
                            w: parseInt(bitem.style.width),
                            h: parseInt(bitem.style.height),
                            id: item.id,
                            price: item.price,
                            z:bitem.style.zIndex,
                        }
                        if (item.yc_cate_id) {
                            frame.cate_id = item.yc_cate_id;
                        }
                        frameGoods.unshift(frame)
                    })

                })

                frames['goods'] = frameGoods
                data['frame'] = frames
                data['type'] = 'shop'

                if (this.appId) {

                    data['goods'] = astGoods;

                    this.$swal.close();
                    
                    this.$router.push('/ast/shop/scheme/info?data=' + JSON.stringify(data))

                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.deck-page {
    display: flex;

    .page-left {
        margin-right: 20px;

        .deck-area-box {
            width: 644px;
            background: #F5F5F5;

            .deck-area-head {
                width: 644px;
                height: 48px;
                background: rgba(51, 51, 51, 0.04);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 12px;

                .copy-box {
                    cursor: pointer;

                    img {
                        width: 32px;
                        height: 32px;
                        margin-right: 12px;
                    }
                }

                .auto-btn {
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    background: #FFFFFF;
                    border-radius: 74px;
                    cursor: pointer;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }

                    span {
                        color: #333333;
                        font-size: 14px;
                    }
                }
            }

            .deck-area {
                width: 600px;
                height: 600px;
                border: 1px dashed #000000;
                margin: 0 auto;
            }

            .deck-area-tool {
                width: 644px;
                height: 48px;
                display: flex;
                align-items: center;
                padding-left: 24px;

                .tool-item {
                    height: 48px;
                    display: flex;
                    align-items: center;
                    margin-right: 16px;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    span {
                        color: #333333;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .page-right {
        .next {
            margin-top: 32px;
        }

        .goods-scroll {
            height: 528px;
            margin-top: 32px;

            .goods-list {
                width: 100%;
                overflow-x: hidden;

                .goods-block {
                    cursor: pointer;
                    height: 260px;
                    width: 200px;
                    margin-bottom: 36px;
                    float: left;
                    margin-right: 30px;
                    position: relative;

                    &.third {
                        margin-right: 0;
                    }

                    &:hover .del-btn {
                        opacity: 1;
                    }

                    .del-btn {
                        width: 24px;
                        height: 24px;
                        background-color: white;
                        border: 1px solid #EEEEEE;
                        border-radius: 50%;
                        cursor: pointer;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        z-index: 99;
                        font-size: 12px;
                        opacity: 0;

                        span {
                            font-size: 12px;
                        }
                    }
                }


            }
        }

        .tips-box {
            width: 480px;
            height: 423px;
            background: url('/assets/images/deck/tips-bg.png') no-repeat;
            background-size: 100%;
            margin-top: 32px;
            padding-top: 34px;

            .tips-item {
                margin-left: 24px;
                margin-bottom: 34px;

                .tips-label {
                    width: fit-content;
                    position: relative;

                    span {
                        color: #333333;
                        font-size: 24px;
                        font-weight: bold;
                        position: relative;
                        z-index: 2;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        height: 12px;
                        border-radius: 17px;
                        background: linear-gradient(328deg, #AEAEAE 0%, #FFFFFF 100%);
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }

                .tips {
                    color: #333333;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 16px;
                }
            }
        }

        .select-tips {
            width: 180px;
            height: 62px;
            margin-top: 12px;
            position: relative;

            img {
                width: 180px;
                height: 62px;
            }

            .tips {
                width: 100%;
                text-align: center;
                color: #333333;
                font-size: 14px;
                position: absolute;
                top: 26px;
                left: 0;
            }
        }

        .goods-select {
            display: flex;
            align-items: center;

            .item {
                width: 106px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(96deg, #5F5F5F 0%, #333333 100%);
                margin-right: 16px;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }

                span {
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }
        }
    }
}
</style>