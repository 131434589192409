// import {getTransparentImageInnerRect, createImage, computedRealWidthHeight} from '@/utils/image'
import {getTransparentImageInnerRect, createImage, getCanvas} from '@/utils/image'

/**
 * @typedef {{maxWidth?: number, maxHeight?: number, class?: number}} InitOptions
 * @typedef {['left' | 'center' | 'right', 'top' | 'center' | 'bottom']} Align
 */

/** 商品元素 */
export class GoodsElement {
  _type = 'image'
  get type() {return this._type}
  active = false
  /** 商品类型 */
  _class = 0
  get class() {return this._class}
  _originUrl = ''
  get originUrl() {return this._originUrl}
  // 兼容属性
  get image() {return this._originUrl}
  _url = ''
  get url() {return this._url}
  // 兼容属性
  get displayImage() {return this._url}
  /** 
   * 原始内部实体数据
   * @type {import('@/utils/image').InnerRect}
   */
  _originInner = undefined
  get originInner() {return this._originInner}
  /** 原始内部实体数据百分比 */
  _originInnerPercent = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }
  /** 实际使用的样式 */
  style = {
    left: '0px',
    top: '0px',
    width: "200px",
    height: "200px",
  }
  /** 
   * 序列化
   * @param {GoodsElement} data 
   */
  static serialize(data) {
    if (!(data instanceof GoodsElement)) {
      throw new Error("错误的类型")
    }
    return {
      type: data.type,
      image: data.image,
      class: data.class,
      style: {
          left: data.style.left,
          top: data.style.top,
          zIndex: data.style.zIndex,
          width: data.style.width,
          height: data.style.height,
      }
    }
  }
  /** 
   * 反序列化
   * @param {Object | string} data 
   */
  static async deserialize(data) {
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }
    const goods = new GoodsElement()
    await goods.init(data.image, {
      class: data.class,
    })
    goods.style.left = data.style.left
    goods.style.top = data.style.top
    goods.style.zIndex = data.style.zIndex
    goods.style.width = data.style.width
    goods.style.height = data.style.height
    return goods
  }
  constructor() {
    //
  }
  /**
   * 初始化
   * @param {string} url 
   * @param {InitOptions?} options 
   * @returns {Promise<GoodsElement>}
   */
  async init(url, options) {
    this._class = options.class

    await this._loadImage(url)

    // 设置样式
    let _maxWidth = 200
    let _maxHeight = 200
    if (typeof options.maxWidth === 'number') {
      _maxWidth = options.maxWidth
    }
    if (typeof options.maxHeight === 'number') {
      _maxHeight = options.maxHeight
    }
    let _width
    let _height
    if (this._originInner.width > this._originInner.height) {
      _width = _maxWidth
      _height = _maxWidth / this._originInner.width * this._originInner.height
    } else {
      _width = _maxHeight / this._originInner.height * this._originInner.width
      _height = _maxHeight
    }
    this._setStyle(undefined, undefined, _width, _height)
    return this
  }
  /**
   * 
   * @param {number} left 
   * @param {number} top 
   * @param {number} width 
   * @param {number} height 
   */
  _setStyle(left, top, width, height) {
    if (left !== undefined) {
      this.style.left = `${left}px`
    }
    if (top !== undefined) {
      this.style.top = `${top}px`
    }
    if (width !== undefined) {
      this.style.width = `${width}px`
    }
    if (height !== undefined) {
      this.style.height = `${height}px`
    } 
  }
  async _loadImage(originUrl) {
    this._originUrl = originUrl
    // 加载图片
    const img = await createImage(this._originUrl)
    const innerRect = getTransparentImageInnerRect(img)
    this._originInner = innerRect
    this._originInnerPercent.left = (innerRect.x / innerRect.imageWidth)
    this._originInnerPercent.right = ((innerRect.imageWidth - innerRect.x2) / innerRect.imageWidth)
    this._originInnerPercent.top = (innerRect.y / innerRect.imageHeight)
    this._originInnerPercent.bottom = ((innerRect.imageHeight - innerRect.y2) / innerRect.imageHeight)
    // 获取去除透明边的图片
    const canvas = getCanvas(
      this._originInner.width,
      this._originInner.height,
    )
    const ctx = canvas.getContext('2d')
    ctx.drawImage(
      img, 
      this._originInner.x,
      this._originInner.y,
      this._originInner.width,
      this._originInner.height,
      0,
      0,
      this._originInner.width,
      this._originInner.height,
    )
    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => resolve(blob), undefined, 0.7)
    })
    // const blob = await canvas.convertToBlob({quality: 0.7})
    this._url = URL.createObjectURL(blob)
  }
}
